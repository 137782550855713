<template>
  <v-container fluid>
    <v-row class="child-flex mx-2">
      <v-card class="mt-3">
        <v-card-title> Passwort ändern </v-card-title>
        <v-card-text>
          <v-form v-model="pwdFormValid" ref="pwdForm">
            <v-text-field
              type="password"
              label="Aktuelles Passwort"
              v-model="oldPassword"
              required
              hide-details
            />
            <v-text-field
              type="password"
              label="Neues Passwort"
              v-model="newPassword"
              required
              hide-details
            />
            <v-text-field
              type="password"
              label="Neues Passwort wiederholen"
              v-model="newPasswordVerify"
              required
              hide-details
            />
            <v-btn
              :disabled="!canChangePwd"
              class="ml-0 mt-3"
              @click="changePassword"
              >Passwort ändern</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="child-flex mx-2 pt-2">
      <v-card>
        <v-card-title>Einstellungen</v-card-title>
        <v-card-text>
          <p>
            Diese Einstellungen werden in deinem Browser / App gespeichert.
            Somit ist es möglich dass diese Einstellungen zurückgesetzt werden
            wenn du den Browsercache oder denn App Cache leerst.
          </p>
          <p class="text-subtitle-2">
            Namesdarstellung: (Wird aktuell nur für die Teilnehmerliste
            verwendet.)
          </p>
          <v-radio-group row v-model="nameDisplay">
            <v-radio label="Vorname Nachname" value="first-last"> </v-radio>
            <v-radio label="Nachname Vorname" value="last-first"> </v-radio>
          </v-radio-group>
          <p class="text-subtitle-2">Sortierung von Teilnehmerlisten:</p>
          <v-radio-group row v-model="participantSorting">
            <v-radio label="Anmeldedatum" value="date"> </v-radio>
            <v-radio label="Vorname Nachname" value="first-last"> </v-radio>
            <v-radio label="Nachname Vorname" value="last-first"> </v-radio>
          </v-radio-group>
          <p class="text-subtitle-2">Tourendarstellung:</p>
          <v-radio-group row v-model="eventDisplay">
            <v-radio label="Liste" value="list"> </v-radio>
            <v-radio label="Tabelle (im Aufbau)" value="table"></v-radio>
            <v-radio label="Kalender (im Aufbau)" value="calendar"> </v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'pinia'
import { createNotification } from '@/utils'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'Profile',
  components: {},
  props: {},
  data() {
    return {
      // Password Change
      oldPassword: '',
      newPassword: '',
      newPasswordVerify: '',
      pwdFormValid: false,
    }
  },
  computed: {
    canChangePwd() {
      return !!this.oldPassword && !!this.newPassword && this.newPassword === this.newPasswordVerify
    },
    ...mapState(useUserStore, ['settings']),
    nameDisplay: {
      get() {
        return this.settings.nameDisplay
      },
      set(value) {
        useUserStore().setConfig({ key: 'nameDisplay', value })
      },
    },
    participantSorting: {
      get() {
        return this.settings.participantSorting
      },
      set(value) {
        useUserStore().setConfig({ key: 'participantSorting', value })
      },
    },
    eventDisplay: {
      get() {
        return this.settings.eventDisplay
      },
      set(value) {
        useUserStore().setConfig({ key: 'eventDisplay', value })
      },
    },
  },
  methods: {
    changePassword() {
      useAuthStore().changePassword({
        oldPwd: this.oldPassword,
        newPwd: this.newPassword,
        newPwdRepeated: this.newPasswordVerify,
      }).then(() => {
        createNotification('Passwort geändert.', 'success')
        this.$refs.pwdForm.reset()
      }).catch(() => {
        createNotification('Passwort ändern fehlgeschlage', 'error')
      })
    },
  },
}

</script>
